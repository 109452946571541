import MeetingProvider from './meeting/MeetingProvider';
import {
  MeetingConsumer,
  useMeeting
} from './meeting/meetingProviderContextDef';
import useParticipant from './participant/useParticipant';
import useConnection from './connection/useConnection';
import usePubSub from './pubSub/usePubSub';
import useFile from './upload/useFile';
import useMediaDevice from './useMediaDevice';
import useTranscription from './transcription/transcription';
import useCharacter from './character/useCharacter';
import useWhiteboard from './whiteboard/useWhiteboard';
import {
  VideoSDK,
  CameraDeviceInfo,
  MicrophoneDeviceInfo,
  DeviceInfo
} from '@videosdk.live/js-sdk';

const Constants = VideoSDK.Constants;

/**
 * @param {{
 *  cameraId?: string | undefined,
 *  encoderConfig?: "h90p_w160p" | "h180p_w320p" | "h216p_w384p"
 *     | "h360p_w640p" | "h540p_w960p" | "h720p_w1280p" | "h1080p_w1920p"
 *     | "h1440p_w2560p" | "h2160p_w3840p" | "h120p_w160p" | "h180p_w240p"
 *     | "h240p_w320p" | "h360p_w480p" | "h480p_w640p" | "h540p_w720p"
 *     | "h720p_w960p" | "h1080p_w1440p" | "h1440p_w1920p" | undefined,
 *  facingMode?: "front" | "environment" | undefined,
 *  optimizationMode?: "text"| "motion" | "detail" | undefined
 *  multiStream?: boolean
 * }}
 */
const createCameraVideoTrack = async ({
  cameraId = undefined,
  encoderConfig = undefined,
  facingMode = undefined,
  optimizationMode = undefined,
  multiStream = true
}) => {
  return await VideoSDK.createCameraVideoTrack({
    cameraId,
    encoderConfig,
    facingMode,
    optimizationMode,
    multiStream
  });
};

/**
 * @param {{
 *  encoderConfig?: "h360p_30fps" | "h720p_5fps" | "h720p_15fps"
 *       | "h1080p_15fps" | "h1080p_30fps" | undefined,
 *  optimizationMode?: "text"| "motion" | "detail" | undefined,
 *  withAudio?:"enable" | "disable"
 * }}
 */
const createScreenShareVideoTrack = async ({
  encoderConfig = undefined,
  optimizationMode = undefined,
  withAudio = 'enable'
}) => {
  return await VideoSDK.createScreenShareVideoTrack({
    encoderConfig,
    optimizationMode,
    withAudio
  });
};

/**
 * @param {{
 *  noiseConfig?: {
 *    echoCancellation: boolean,
 *    autoGainControl: boolean,
 *    noiseSuppression: boolean
 *  } | undefined,
 *  encoderConfig?: "speech_low_quality"
 *     | "speech_standard" | "music_standard"
 *     | "standard_stereo" | "high_quality"
 *     | "high_quality_stereo" | undefined
 *  microphoneId?: string | undefined
 * }}
 */
const createMicrophoneAudioTrack = async ({
  noiseConfig = undefined,
  encoderConfig = undefined,
  microphoneId = undefined
}) => {
  return await VideoSDK.createMicrophoneAudioTrack({
    noiseConfig,
    encoderConfig,
    microphoneId
  });
};

/**
 * @param {{
 * timeoutDuration?: number | undefined,
 * }}
 * @returns {Promise<{downloadSpeed: number,uploadSpeed: number}> }
 */
const getNetworkStats = async ({ timeoutDuration } = {}) => {
  return await VideoSDK.getNetworkStats({ timeoutDuration });
};

export {
  MeetingProvider,
  useMeeting,
  useTranscription,
  useWhiteboard,
  useCharacter,
  useParticipant,
  usePubSub,
  useFile,
  MeetingConsumer,
  useConnection,
  Constants,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  createScreenShareVideoTrack,
  useMediaDevice,
  getNetworkStats,
  CameraDeviceInfo,
  MicrophoneDeviceInfo,
  DeviceInfo
};
