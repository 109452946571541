import React from 'react';
import { useContext, createContext, useEffect } from 'react';
import { eventEmitter, events } from '../utils';
export const MeetingProviderContex = createContext();

/**
 *
 * @param {{
 *  onParticipantJoined?: (participant: Participant) => void,
 *  onParticipantLeft?: (participant: Participant) => void,
 *  onSpeakerChanged?: (activeSpeakerId: string?) => void,
 *  onPresenterChanged?: (presenterId: string?) => void,
 *  onMainParticipantChanged?: Function,
 *  onEntryRequested?: ({
 *    participantId,
 *    name,
 *    allow,
 *    deny
 *  }:{
 *    participantId: string,
 *    name: string,
 *    allow: Function,
 *    deny: Function
 *  }) => void,
 *  onEntryResponded?: ({
 *    participantId,
 *    decision
 *  }:{
 *    participantId: string,
 *    decision: string
 *  }) => void,
 *  onRecordingStarted?: Function,
 *  onRecordingStopped?: Function,
 *  onChatMessage?: Function,
 *  onMeetingJoined?: Function,
 *  onMeetingLeft?: Function,
 *  onLiveStreamStarted?: Function,
 *  onLiveStreamStopped?: Function,
 *  onVideoStateChanged?: Function,
 *  onVideoSeeked?: Function,
 *  onWebcamRequested?: ({
 *    participantId,
 *    accept,
 *    reject
 *  }:{
 *    participantId: string,
 *    accept: Function,
 *    reject: Function
 *  }) => void,
 *  onMicRequested?: ({
 *    participantId,
 *    accept,
 *    reject
 *  }:{
 *    participantId: string,
 *    accept: Function,
 *    reject: Function
 *  }) => void,
 *  onPinStateChanged?: Function,
 *  onConnectionOpen?: Function,
 *  onConnetionClose?: Function,
 *  onSwitchMeeting?: Function,
 *  onError?: ({
 *    code,
 *    message
 *  }:{
 *    code: string,
 *    message: string
 *  }) => void,
 *  onHlsStarted?: ({
 *    downstreamUrl,
 *  }:{
 *    downstreamUrl: string,
 *  }) => void,
 *  onHlsStopped?: Function,
 *  onHlsStateChanged?: ({
 *    status,
 *    downstreamUrl,
 *    playbackHlsUrl,
 *    livestreamUrl
 *  }:{
 *    status: "HLS_STARTING" | "HLS_STARTED" | "HLS_PLAYABLE" | "HLS_STOPPING" | "HLS_STOPPING",
 *    downstreamUrl: string,
 *    playbackHlsUrl: string,
 *    livestreamUrl: string
 *  }) => void,
 *  onRecordingStateChanged?: ({
 *    status
 *  }:{
 *    status: "RECORDING_STARTING" | "RECORDING_STARTED" | "RECORDING_STOPPING" | "RECORDING_STOPPING",
 *  }) => void,
 *  onLivestreamStateChanged?: ({
 *     status: "LIVESTREAM_STARTING" | "LIVESTREAM_STARTED" | "LIVESTREAM_STOPPING" | "LIVESTREAM_STOPPING",
 *  }:{
 *    status: string
 *  }) => void,
 *  onMeetingStateChanged?: ({
 *    state
 *  }:{
 *    state: string
 *  }) => void,
 *}}
 * @returns {{
 *  meetingId: string,
 *  meeting: Meeting,
 *  localParticipant: Participant,
 *  activeSpeakerId: string,
 *  participants: Map<string, Participant>,
 *  characters: Map<string, Character>;
 *  pinnedParticipants: Map<string, {cam:boolean, share:boolean}>,
 *  presenterId?: string,
 *  localMicOn: boolean,
 *  localWebcamOn: boolean,
 *  isRecording: boolean,
 *  recordingState: string,
 *  livestreamState: string,
 *  hlsState: string,
 *  hlsUrls: {
 *    downstreamUrl: string,
 *    playbackHlsUrl: string,
 *    livestreamUrl: string
 *  },
 *  localScreenShareOn: boolean,
 *  connections: Map<string, Connection>,
 *  join: () => void
 *  leave: () => void
 *  end: () => void
 *  unmuteMic: (customAudioTrack?: MediaStream | undefined) => void
 *  muteMic: () => void
 *  toggleMic: (customAudioTrack?: MediaStream | undefined) => void
 *  enableWebcam: (customVideoTrack?: MediaStream | undefined) => void
 *  disableWebcam: () => void
 *  toggleWebcam: (customVideoTrack?: MediaStream | undefined) => void
 *  enableScreenShare: (customScreenShareTrack?: MediaStream | undefined) => void
 *  disableScreenShare: () => void
 *  toggleScreenShare: (customScreenShareTrack?: MediaStream | undefined) => void
 *  startRecording: (webhookUrl?: string, awsDirPath?: string, config?: {
 *    layout: {
 *      type: "GRID" | "SPOTLIGHT" | "SIDEBAR";
 *      priority: "SPEAKER" | "PIN";
 *      gridSize: number;
 *    },
 *    orientation: "landscape"  | "portrait",
 *    theme: "DEFAULT" | "DARK" | "LIGHT",
 *    quality: "low" | "med" | "high",
 *    mode: "video-and-audio" | "audio",
 *   },
 *  transcription?: {
 *    enabled: boolean,
 *    summary?: {
 *      enabled: boolean,
 *      prompt?: string
 *    }
 *  }) => void
 *  stopRecording: () => void
 *  startLiveStream: (outputs: Array<{
 *       url: string;
 *       streamKey: string;
 *   }>,
 *   config?: {
 *    layout: {
 *      type: "GRID" | "SPOTLIGHT" | "SIDEBAR";
 *      priority: "SPEAKER" | "PIN";
 *      gridSize: number;
 *    },
 *    theme: "DEFAULT" | "DARK" | "LIGHT",
 *   }) => void
 *  stopLiveStream: () => void
 *  startHls: (config?: {
 *    layout: {
 *      type: "GRID" | "SPOTLIGHT" | "SIDEBAR";
 *      priority: "SPEAKER" | "PIN";
 *      gridSize: number;
 *    },
 *    orientation: "landscape"  | "portrait",
 *    theme: "DEFAULT" | "DARK" | "LIGHT",
 *    quality: "low" | "med" | "high",
 *    mode: "video-and-audio" | "audio",
 *   },
 *  transcription?: {
 *    enabled: boolean,
 *    summary?: {
 *      enabled: boolean,
 *      prompt?: string
 *    }
 *  }) => void
 *  stopHls: () => void
 *  getMics: () => Promise<{
 *       deviceId: string;
 *       label: string;
 *   }[]>void
 *  getWebcams: () => Promise<{
 *       deviceId: string;
 *       label: string;
 *       facingMode: "environment" | "front"
 *   }[]>void
 *  changeMic: (object: string | MediaStream) => void
 *  changeMode(mode: 'CONFERENCE' | 'VIEWER'): void;
 *  changeWebcam: (object: string | MediaStream) => void
 *  startVideo: ({ link }: {
 *       link: string;
 *   }) => void
 *  stopVideo: () => void
 *  pauseVideo: ({ currentTime }: {
 *       currentTime: number;
 *   }) => void
 *  resumeVideo: () => void
 *  seekVideo: ({ currentTime }: {
 *       currentTime: number;
 *   }) => void
 *  connectTo: ({ meetingId, payload }: {
 *       meetingId: string;
 *       payload: string;
 *   }) => void
 *
 * }}
 */
export const useMeeting = ({
  onParticipantJoined = () => {},
  onParticipantLeft = () => {},
  onSpeakerChanged = () => {},
  onPresenterChanged = () => {},
  onMainParticipantChanged = () => {},
  onEntryRequested = () => {},
  onEntryResponded = () => {},
  onRecordingStarted = () => {},
  onRecordingStopped = () => {},
  onChatMessage = () => {},
  onMeetingJoined = () => {},
  onMeetingLeft = () => {},
  onLiveStreamStarted = () => {},
  onLiveStreamStopped = () => {},
  onVideoStateChanged = () => {},
  onVideoSeeked = () => {},
  onWebcamRequested = () => {},
  onMicRequested = () => {},
  onPinStateChanged = () => {},
  onConnectionOpen = () => {},
  onConnetionClose = () => {},
  onSwitchMeeting = () => {},
  onError = () => {},
  onHlsStarted = () => {},
  onHlsStopped = () => {},
  onHlsStateChanged = () => {},
  onRecordingStateChanged = () => {},
  onLivestreamStateChanged = () => {},
  onMeetingStateChanged = () => {},
  onParticipantModeChanged = () => {},
  onCharacterJoined = () => {},
  onCharacterLeft = () => {}
} = {}) => {
  useEffect(() => {
    eventEmitter.on(events['participant-joined'], onParticipantJoined);
    eventEmitter.on(events['participant-left'], onParticipantLeft);
    eventEmitter.on(events['speaker-changed'], onSpeakerChanged);
    eventEmitter.on(events['presenter-changed'], onPresenterChanged);
    eventEmitter.on(
      events['main-participant-changed'],
      onMainParticipantChanged
    );
    eventEmitter.on(events['entry-requested'], onEntryRequested);
    eventEmitter.on(events['entry-responded'], onEntryResponded);
    eventEmitter.on(events['recording-started'], onRecordingStarted);
    eventEmitter.on(events['recording-stopped'], onRecordingStopped);
    eventEmitter.on(events['chat-message'], onChatMessage);
    eventEmitter.on(events['meeting-joined'], onMeetingJoined);
    eventEmitter.on(events['meeting-left'], onMeetingLeft);
    eventEmitter.on(events['live-stream-started'], onLiveStreamStarted);
    eventEmitter.on(events['live-stream-stopped'], onLiveStreamStopped);
    eventEmitter.on(events['video-state-changed'], onVideoStateChanged);
    eventEmitter.on(events['video-seeked'], onVideoSeeked);
    eventEmitter.on(events['webcam-requested'], onWebcamRequested);
    eventEmitter.on(events['mic-requested'], onMicRequested);
    eventEmitter.on(events['pin-state-changed'], onPinStateChanged);
    eventEmitter.on(events['connection-open'], onConnectionOpen);
    eventEmitter.on(events['connection-close'], onConnetionClose);
    eventEmitter.on(events['switch-meeting'], onSwitchMeeting);
    eventEmitter.on(events['error'], onError);
    eventEmitter.on(events['hls-started'], onHlsStarted);
    eventEmitter.on(events['hls-stopped'], onHlsStopped);
    eventEmitter.on(events['hls-state-changed'], onHlsStateChanged);
    eventEmitter.on(events['recording-state-changed'], onRecordingStateChanged);
    eventEmitter.on(
      events['livestream-state-changed'],
      onLivestreamStateChanged
    );
    eventEmitter.on(events['meeting-state-changed'], onMeetingStateChanged);
    eventEmitter.on(
      events['participant-mode-changed'],
      onParticipantModeChanged
    );
    eventEmitter.on(events['character-joined'], onCharacterJoined);
    eventEmitter.on(events['character-left'], onCharacterLeft);

    return () => {
      eventEmitter.off(events['meeting-state-changed'], onMeetingStateChanged);
      eventEmitter.off(events['participant-joined'], onParticipantJoined);
      eventEmitter.off(events['participant-left'], onParticipantLeft);
      eventEmitter.off(events['speaker-changed'], onSpeakerChanged);
      eventEmitter.off(events['presenter-changed'], onPresenterChanged);
      eventEmitter.off(
        events['main-participant-changed'],
        onMainParticipantChanged
      );
      eventEmitter.off(events['entry-requested'], onEntryRequested);
      eventEmitter.off(events['entry-responded'], onEntryResponded);
      eventEmitter.off(events['recording-started'], onRecordingStarted);
      eventEmitter.off(events['recording-stopped'], onRecordingStopped);
      eventEmitter.off(events['chat-message'], onChatMessage);
      eventEmitter.off(events['meeting-joined'], onMeetingJoined);
      eventEmitter.off(events['meeting-left'], onMeetingLeft);
      eventEmitter.off(events['live-stream-started'], onLiveStreamStarted);
      eventEmitter.off(events['live-stream-stopped'], onLiveStreamStopped);
      eventEmitter.off(events['video-state-changed'], onVideoStateChanged);
      eventEmitter.off(events['video-seeked'], onVideoSeeked);
      eventEmitter.off(events['webcam-requested'], onWebcamRequested);
      eventEmitter.off(events['mic-requested'], onMicRequested);
      eventEmitter.off(events['pin-state-changed'], onPinStateChanged);
      eventEmitter.off(events['connection-open'], onConnectionOpen);
      eventEmitter.off(events['connection-close'], onConnetionClose);
      eventEmitter.off(events['switch-meeting'], onSwitchMeeting);
      eventEmitter.off(events['error'], onError);
      eventEmitter.off(events['hls-started'], onHlsStarted);
      eventEmitter.off(events['hls-stopped'], onHlsStopped);
      eventEmitter.off(events['hls-state-changed'], onHlsStateChanged);
      eventEmitter.off(
        events['recording-state-changed'],
        onRecordingStateChanged
      );
      eventEmitter.off(
        events['livestream-state-changed'],
        onLivestreamStateChanged
      );
      eventEmitter.off(
        events['participant-mode-changed'],
        onParticipantModeChanged
      );
      eventEmitter.off(events['character-joined'], onCharacterJoined);
      eventEmitter.off(events['character-left'], onCharacterLeft);
    };
  }, []);
  return useContext(MeetingProviderContex);
};

export const MeetingConsumer = ({
  children,
  onParticipantJoined = () => {},
  onParticipantLeft = () => {},
  onSpeakerChanged = () => {},
  onPresenterChanged = () => {},
  onMainParticipantChanged = () => {},
  onEntryRequested = () => {},
  onEntryResponded = () => {},
  onRecordingStarted = () => {},
  onRecordingStopped = () => {},
  onChatMessage = () => {},
  onMeetingJoined = () => {},
  onMeetingLeft = () => {},
  onLiveStreamStarted = () => {},
  onLiveStreamStopped = () => {},
  onVideoStateChanged = () => {},
  onVideoSeeked = () => {},
  onWebcamRequested = () => {},
  onMicRequested = () => {},
  onPinStateChanged = () => {},
  onConnectionOpen = () => {},
  onConnetionClose = () => {},
  onSwitchMeeting = () => {},
  onError = () => {},
  onHlsStarted = () => {},
  onHlsStopped = () => {},
  onHlsStateChanged = () => {},
  onRecordingStateChanged = () => {},
  onLivestreamStateChanged = () => {},
  onMeetingStateChanged = () => {},
  onParticipantModeChanged = () => {},
  onCharacterJoined = () => {},
  onCharacterLeft = () => {}
}) => {
  useEffect(() => {
    eventEmitter.on(events['meeting-state-changed'], onMeetingStateChanged);
    eventEmitter.on(events['participant-joined'], onParticipantJoined);
    eventEmitter.on(events['participant-left'], onParticipantLeft);
    eventEmitter.on(events['speaker-changed'], onSpeakerChanged);
    eventEmitter.on(events['presenter-changed'], onPresenterChanged);
    eventEmitter.on(
      events['main-participant-changed'],
      onMainParticipantChanged
    );
    eventEmitter.on(events['entry-requested'], onEntryRequested);
    eventEmitter.on(events['entry-responded'], onEntryResponded);
    eventEmitter.on(events['recording-started'], onRecordingStarted);
    eventEmitter.on(events['recording-stopped'], onRecordingStopped);
    eventEmitter.on(events['chat-message'], onChatMessage);
    eventEmitter.on(events['meeting-joined'], onMeetingJoined);
    eventEmitter.on(events['meeting-left'], onMeetingLeft);
    eventEmitter.on(events['live-stream-enabled'], onLiveStreamStarted);
    eventEmitter.on(events['live-stream-disabled'], onLiveStreamStopped);
    eventEmitter.on(events['video-state-changed'], onVideoStateChanged);
    eventEmitter.on(events['video-seeked'], onVideoSeeked);
    eventEmitter.on(events['webcam-requested'], onWebcamRequested);
    eventEmitter.on(events['mic-requested'], onMicRequested);
    eventEmitter.on(events['pin-state-changed'], onPinStateChanged);
    eventEmitter.on(events['connection-open'], onConnectionOpen);
    eventEmitter.on(events['connection-close'], onConnetionClose);
    eventEmitter.on(events['switch-meeting'], onSwitchMeeting);
    eventEmitter.on(events['error'], onError);
    eventEmitter.on(events['hls-started'], onHlsStarted);
    eventEmitter.on(events['hls-stopped'], onHlsStopped);
    eventEmitter.on(events['hls-state-changed'], onHlsStateChanged);
    eventEmitter.on(events['recording-state-changed'], onRecordingStateChanged);
    eventEmitter.on(
      events['livestream-state-changed'],
      onLivestreamStateChanged
    );
    eventEmitter.on(
      events['participant-mode-changed'],
      onParticipantModeChanged
    );
    eventEmitter.on(events['character-joined'], onCharacterJoined);
    eventEmitter.on(events['character-left'], onCharacterLeft);

    return () => {
      eventEmitter.off(events['meeting-state-changed'], onMeetingStateChanged);
      eventEmitter.off(events['participant-joined'], onParticipantJoined);
      eventEmitter.off(events['participant-left'], onParticipantLeft);
      eventEmitter.off(events['speaker-changed'], onSpeakerChanged);
      eventEmitter.off(events['presenter-changed'], onPresenterChanged);
      eventEmitter.off(
        events['main-participant-changed'],
        onMainParticipantChanged
      );
      eventEmitter.off(events['entry-requested'], onEntryRequested);
      eventEmitter.off(events['entry-responded'], onEntryResponded);
      eventEmitter.off(events['recording-started'], onRecordingStarted);
      eventEmitter.off(events['recording-stopped'], onRecordingStopped);
      eventEmitter.off(events['chat-message'], onChatMessage);
      eventEmitter.off(events['meeting-joined'], onMeetingJoined);
      eventEmitter.off(events['meeting-left'], onMeetingLeft);
      eventEmitter.off(events['live-stream-enabled'], onLiveStreamStarted);
      eventEmitter.off(events['live-stream-disabled'], onLiveStreamStopped);
      eventEmitter.off(events['video-state-changed'], onVideoStateChanged);
      eventEmitter.off(events['video-seeked'], onVideoSeeked);
      eventEmitter.off(events['webcam-requested'], onWebcamRequested);
      eventEmitter.off(events['mic-requested'], onMicRequested);
      eventEmitter.off(events['pin-state-changed'], onPinStateChanged);
      eventEmitter.off(events['connection-open'], onConnectionOpen);
      eventEmitter.off(events['connection-close'], onConnetionClose);
      eventEmitter.off(events['switch-meeting'], onSwitchMeeting);
      eventEmitter.off(events['error'], onError);
      eventEmitter.off(events['hls-started'], onHlsStarted);
      eventEmitter.off(events['hls-stopped'], onHlsStopped);
      eventEmitter.off(events['hls-state-changed'], onHlsStateChanged);
      eventEmitter.off(
        events['recording-state-changed'],
        onRecordingStateChanged
      );
      eventEmitter.off(
        events['livestream-state-changed'],
        onLivestreamStateChanged
      );
      eventEmitter.off(
        events['participant-mode-changed'],
        onParticipantModeChanged
      );

      eventEmitter.off(events['character-joined'], onCharacterJoined);
      eventEmitter.off(events['character-left'], onCharacterLeft);
    };
  }, []);

  return (
    <React.Fragment>
      <MeetingProviderContex.Consumer>
        {children}
      </MeetingProviderContex.Consumer>
    </React.Fragment>
  );
};
